import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  'list': [],
  'curent': ""
}

export const departmentSlice = createSlice({
  name: 'department',
  initialState,
  reducers: {
    set_department_list: (state, action) => {
      state.list = action.payload
    },
    set_department_curent: (state, action) => {
      state.curent = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { set_department_list, set_department_curent } = departmentSlice.actions

export default departmentSlice.reducer