/**
|--------------------------------------------------
| STRINGS
| @vunv

|--------------------------------------------------
*/
const get_all_url_from_content = (content) => {
	const url_regex = /(https?:\/\/[^\s]+)/g
	const arr_url = []
	content.replace(url_regex, (url) => {
		arr_url.push(url)
	})
	return arr_url
}

const render_content_hashtag = (text) => {
	const text_break = text
	// eslint-disable-next-line
	return (text_break || "").replace(/(^|\s)(#[a-z\d-]+)/gi,
		(match, space, url) => {
			// return space + '<a rel="noopener noreferrer" class="hashtag" href="' + BASE_URL + '?hashtag=' + url + '" target="_blank"><strong>' + url + '</strong></a>'
			return space + '<strong>' + url + '</strong>'
		}
	)
}


const get_byte = (string = "") => {
	let normal_val = string.toString()
	let byteLen = 0
	for (let i = 0; i < normal_val.length; i++) {
		let c = normal_val.charCodeAt(i)
		byteLen += c < (1 << 7) ? 1 :
			c < (1 << 11) ? 2 :
				c < (1 << 16) ? 3 :
					c < (1 << 21) ? 4 :
						c < (1 << 26) ? 5 :
							c < (1 << 31) ? 6 : Number.NaN
	}
	return byteLen
}

const random_string = () => {
	return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
}

const word_count = (str, word_number, more_text = '', str_max_length = 16, line_max = 1) => {
	let str_input = ''
	let arr_line = []

	// check string multi line
	arr_line = str.split(/[\r\n|\r|\n]+/)
	let full_text_line = arr_line.length || 0
	let full_text_word = str.trim().split(/[\s]+/)

	if (arr_line.length > line_max) {
		str_input = arr_line[0] + ' '

		if (line_max > 1 && line_max < arr_line.length) {
			let new_text = arr_line.splice(0, line_max).join('\n')
			str_input = new_text
		}
	} else {
		str_input = str
	}

    /**
     * - clear space
     * - string -> array
     * - check  length -> join string from array
     */
	const words = str_input.trim().split(/[\s]+/)
	const leng = words.length
	let short_text = ''
	// check word number
	if (leng >= word_number) {
		let text_less = words.slice(0, word_number)
		short_text = text_less.join(' ')
	} else {
		short_text = str_input.trim()
	}

	// check string length text
	if (short_text.length > str_max_length) {
		short_text = str_input.substr(0, str_max_length) + more_text
	}

	let short_text_word = short_text.trim().split(/[\s]+/)
	let short_text_line = short_text.split(/[\r\n|\r|\n]+/)


    /**
     * OUTPUT DATA
     * - full length text
     * - full text
     * - short text after cut
     * - length of short text
     */
	const data = {
		"full_text_length": str.length,
		"full_text_word": full_text_word.length,
		"full_text_line": full_text_line,
		"full_text": str.trim(),
		"short_text": short_text,
		"short_text_length": short_text.length,
		"short_text_word": short_text_word.length,
		"short_text_line": short_text_line.length,
	}

	return data
}

const generate_id = () => {
	let id = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
	// eslint-disable-next-line
	id = id.replace(/[xy]/g, (c) => {
		let r = Math.floor(Math.random() * 16)
		let v
		if (c === 'x') {
			v = r
		} else {
			v = (r & 0x3) | 0x8
		}
		return v.toString(16)
	})
	return id
}

const trim = (text = "") => text.replace(/^\s+|\s+$/gm, '')

export { get_all_url_from_content, get_byte, random_string, render_content_hashtag, word_count, generate_id, trim }