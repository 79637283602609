const { createSlice } = require("@reduxjs/toolkit")

const initialState = {
  isOpen: true,
}

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    toggleSidebar: state => {
      state.isOpen = !state.isOpen
    }
  }
})

export const { toggleSidebar } = sidebarSlice.actions
export default sidebarSlice.reducer