import React, { Component } from 'react'
import './App.css';
import './types/style.scss';
import './types/responsive.scss';
import { store } from './redux/store'
import { Provider } from 'react-redux'
import AppRouter from './routers'
import { ConfigProvider } from 'antd';
import viVN from 'antd/locale/vi_VN'

class App extends Component {

	componentDidMount() {
	}
	render() {
		return (<Provider store={store} >
			<ConfigProvider locale={viVN}>
			<AppRouter />
			</ConfigProvider>
		</Provider>)
	}
}


export default App;
