import { configureStore } from '@reduxjs/toolkit'
import counterReducer from '../features/counter/counterSlice'
import authReducer from './authSlice'
import notiReducer from './notiSlice'
import notiFixedReducer from './notiFixedSlice'
import imageReducer from './imageSlice'
import deparmentReducer from './departmentSlice'
import sidebarReducer from './sidebarSlice'

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    auth: authReducer,
    notification: notiReducer,
    notiFixed: notiFixedReducer,
    image: imageReducer,
    department: deparmentReducer,
    sidebar: sidebarReducer,
  },
})