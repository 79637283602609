import axios from 'axios'
import { get_local_storage, set_local_storage } from "./storage"
import { CONFIG } from 'configs'

const sleep = duration => new Promise(resolve => setTimeout(resolve, duration))

// create an axios instance
const http_request_config = axios.create({
	// baseURL: CONFIG.SERVER_API, // base_url of api
	timeout: CONFIG.TIMEOUT // Request timeout
})

// request interceptor
http_request_config.interceptors.request.use(
	config => {
		config.baseURL = `${CONFIG.SERVER_API}`
		try {
			if (get_local_storage('token_config')) {
				if (config.up_file) {
					config.baseURL = `${CONFIG.SERVER_API_UPLOAD}`
				} else {
					config.headers['Content-Type'] = 'application/json'
					config.data = {
						...config.data,
						token: get_local_storage('token_config')
					}
				}
			}
		} catch (error) {
			console.log('config interceptors err')
		}
		return config
	},
	error => {
		Promise.reject(error)
	}
)

// response Interceptor
http_request_config.interceptors.response.use(
	async (response) => {
		const original_request = response.config
		const { code } = response.data

		// console.log('original_request._retry', original_request._retry)

		// refresh token = new_token
		if (code === 3) {
			const { new_token } = response.data
			set_local_storage("token", new_token)

			if (!original_request._retry) {
				original_request._retry = true
				const data_with_new_token = {
					...JSON.parse(original_request.data),
					token: new_token
				}
				original_request.data = data_with_new_token
				await sleep(1000)
				return await http_request_config(original_request)
			}
		}

		// session end -> logout & clear all data user
		if (code === 100) {
			// remove_token_storage()
			// return redirect_page('/')
		}

		return response.data
	},
	error => {
		console.log("connect server error ", error)
		return { code: -1 }
	}
)

export default http_request_config