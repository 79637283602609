/**
|--------------------------------------------------
| FALLBACK SPINNER
| @vunv

|--------------------------------------------------
*/
import React from 'react'
import { Spinner } from 'reactstrap'
import "./FallbackSpinner.scss"
import { Spin } from 'antd'

const FallbackSpinner = () => (<div className="fallback-spinner"><Spin size='large' />
</div>)

export default React.memo(FallbackSpinner)