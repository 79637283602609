import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  'data': {
    'show': false
  }
}

export const imageSlice = createSlice({
  name: 'image',
  initialState,
  reducers: {
    show_images: (state, action) => {

      state.data = {...action.payload, "show": true}
    },
    hide_images: (state) => {
      state.data.show = false
    },
  },
})

// Action creators are generated for each case reducer function
export const { show_images, hide_images } = imageSlice.actions

export default imageSlice.reducer