import {
	SERVER_API, BASE_URL_FILE_API, SERVER_API_UPLOAD,
	SERVER_SOCKET_CHAT,
	BASE_URL, ENV, SERVER_PORT,
	SERVER_LIVESTREAM,
	BASE_URL_FILE_LIVESTREAM,
	SERVER_LIVESTREAM_TURN,
	FACEBOOK_APP,
	GOOGLE_APP
} from "./server_info"

export const CONFIG = {
	SERVER_API, BASE_URL_FILE_API, SERVER_API_UPLOAD,
	SERVER_SOCKET_CHAT,
	BASE_URL, ENV, SERVER_PORT,
	SERVER_LIVESTREAM,
	BASE_URL_FILE_LIVESTREAM,
	SERVER_LIVESTREAM_TURN,
	FACEBOOK_APP,
	GOOGLE_APP,
	// ------  constans ------ 
	DEFAULT_AVATA: '/assets/default_avata.png',
	IMG_FALL_BACK: '/assets/fallback-image.gif',
	SENDER_ID: 842550913355,
	// eslint-disable-next-line
	EMAIL_FORMAT: /^[a-z]+[a-z0-9._]+@[a-z\-]+\.[a-z.]{2,8}$/,
	// eslint-disable-next-line
	EMAIL_OR_PHONE_FORMAT: /^([a-z]+[a-z0-9._]+@[a-z\-]+\.[a-z.]{2,8})|((0[126389]|09)[0-9]{8})$/,
	// eslint-disable-next-line
	PHONE_FORMAT: /^(0[123689]|09)[0-9]{8}$/,
	// eslint-disable-next-line
	PASSPORT_ID_FORMAT: /^([A-Z]{1}[0-9]{7}|[0-9]{9}|[0-9]{12})$/,
	OTP_CODE_FORMAT: /^[0-9]{6}$/,
	VALIDATE_NAME: /^[0-9a-zA-Z_ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\s]+$/,
	// ORDER_CATE_LIVESTREAM: /^[1-9]\d*$/,
	ORDER_CATE_LIVESTREAM: /^[1-9][0-9]*$/,
	TOKEN_GIFT: /^[1-9][0-9]*$/,

	SLUG_CATE_LIVESTREAM: /^[-0-9a-z]+$/,
	// VALIDATE_NAME: /^[a-z]+[a-z0-9._]+[:space:]+[àáãạảăắằẳẵặâấầẩẫậèéẹẻẽêềếểễệđìíĩỉịòóõọỏôốồổỗộơớờởỡợùúũụủưứừửữựỳỵỷỹýÀÁÃẠẢĂẮẰẲẴẶÂẤẦẨẪẬÈÉẸẺẼÊỀẾỂỄỆĐÌÍĨỈỊÒÓÕỌỎÔỐỒỔỖỘƠỚỜỞỠỢÙÚŨỤỦƯỨỪỬỮỰỲỴỶỸÝ_]*$/,
}
